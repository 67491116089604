<template>
  <div class="mt-3">
    <h6 class="mb-3">SCGames Roadmap / Timeline</h6>
    <ul>
      <li
        v-for="item in plan"
        :key="item.period"
        :style="'--accent-color: ' + item.color"
      >
        <div class="date" :class="!item.completed || 'checked'">
          {{ item.period }}
        </div>
        <div v-for="entry in item.entries" :key="entry.title">
          <div class="title">{{ entry.title }}</div>
          <div class="descr">{{ entry.description }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Experience",
  data() {
    return {
      plan: [
        {
          period: "2024 Q3 - Q4",
          color: "#993fd2",
          completed: false,
          entries: [
            {
              title: "New Slot games",
              description:
                "Get ready to experience non-stop fun with our latest slot games that come packed with the Global Jackpot system, free spins, and tons of entertainment.",
            },
            {
              title: "Global Jackpot",
              description:
                "We're thrilled to introduce the Global Jackpot system across all our games, which will create more excitement and chances to win big for our players.",
            },
          ],
        },
        {
          period: "2024 Q1 - Q2",
          color: "#FF69B4",
          completed: false,
          entries: [
            {
              title: "Marketing Boost",
              description:
                "Our talented marketing team is working hard to bring more awareness and exposure to our product across various platforms, so that we can share the benefits of SCG with more people.",
            },
            {
              title: "Our Product's Re-Branding and UI Enhancements",
              description:
                "Currently, we are focused on delivering a more enjoyable gambling experience to our users by improving our platform's functionality and revamping our brand. Our team is working hard to ensure that our users have a seamless and enjoyable experience while interacting with our platform. We are confident that these improvements will enhance user engagement and satisfaction, and we can't wait to unveil the new and improved version of our product.",
            },
          ],
        },
        {
          period: "2023 Q4",
          color: "#8AAAE5",
          completed: true,
          entries: [
            {
              title: "SCG Token",
              description:
                "Get ready for the launch of SCG Token, which will offer even more value and benefits to our loyal supporters.",
            },
            {
              title: "SCG Staking",
              description:
                "Embark on a transformative journey with our SCG Token Staking.",
            },
          ],
        },
        {
          period: "2023 Q3",
          color: "#F96167",
          completed: true,
          entries: [
            {
              title: "Slots Engine",
              description:
                "Our developers are putting the finishing touches on the SCG Slots Engine, which will provide a seamless and enjoyable experience for all our users.",
            },
          ],
        },
        {
          period: "2023 Q1 - Q2",
          color: "#317773",
          completed: true,
          entries: [
            {
              title: "Leaderboard",
              description:
                "We're excited to announce that our global leaderboard has been launched, adding a new level of competition to our games",
            },
            {
              title: "Minor Marketing Tools",
              description:
                "As part of our commitment to outreach and engagement, we're taking our first steps into marketing by integrating our Telegram and Twitter networks. Stay tuned for more exciting developments in this area.",
            },
          ],
        },
        {
          period: "2022",
          color: "#E3B448",
          completed: true,
          entries: [
            {
              title: "Thundercore Tokens Implemented",
              description:
                "We have implemented the ability to play our games using all the well-known tokens on the Thundercore network, providing our users with even more flexibility and convenience in their gaming experience.",
            },
            {
              title: "Expansion to Polygon and CSC",
              description:
                "We are excited to announce that we have successfully expanded our project to both the Polygon and Coinex Smart Chain networks. Our users can now enjoy seamless gameplay and increased accessibility on these popular networks.",
            }
          ],
        },
        {
          period: "2021",
          color: "#CBD18F",
          completed: true,
          entries: [
            {
              title: "Revolutionizing Cashback",
              description: "With our new and improved cashback system, we are delivering unprecedented amounts of cashback to our customers, setting a new standard in the industry."
            },
            {
              title: "Global Recognition",
              description:
                "We are proud to announce that our project has gained international recognition and is now listed on top dApp catalogs such as dApp.com and Dappradar.com. Our platform has been ranked in the top 5 across all blockchain gambling platforms, a testament to the hard work and dedication of our team.",
            },
            {
              title: "Growth Fund Achievement",
              description:
                "We are thrilled to have completed all the requirements for Phase 1 of the $10 Million Growth Fund by Thundercore and successfully secured our first grant. This achievement has given us the resources to continue developing our platform and providing the best possible experience for our users..",
            },
          ],
        },
        {
          period: "2020",
          color: "#ADD8E6",
          completed: true,
          entries: [
            {
              title: "Dice",
              description:
                "New game successfully launched, offering our users a fun and exciting way to play.",
            },
            {
              title: "Plinko",
              description:
                "New game successfully launched, providing a unique and engaging experience for our users.",
            },
            {
              title: "Videopoker",
              description:
                "New game successfully launched, adding another popular option for our users to enjoy.",
            },
            {
              title: "Baccarat",
              description:
                "New game successfully launched, providing our users with a classic casino experience.",
            },
            {
              title: "Slots",
              description:
                "Simple 777 slot successfully delivered, allowing our users to enjoy a traditional slot machine game.",
            },
          ],
        },
        {
          period: "2019",
          color: "#EEA47F",
          completed: true,
          entries: [
            {
              title: "Keno",
              description:
                "Our first game on Thundercore has been successfully delivered, expanding our platform to a new blockchain.",
            },
            {
              title: "Webpage",
              description:
                "Our first simplified version of the project's webpage has been delivered, making it easier for users to navigate and find information about our platform.",
            },
            {
              title: "Thundercore",
              description:
                "We have made significant progress in our exploration of Thundercore and have taken the first steps towards integrating it into our platform.",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
ul {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
ul::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
ul li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
ul li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

ul li:nth-child(odd) {
  text-align: left;
}
ul li:nth-child(even) {
  text-align: right;
}

/* date */
ul li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* circle */
ul li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;

  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

ul li .date.checked::before {
  content: "✓";
  width: 10rem;
  height: 10rem;
  position: absolute;
  background: transparent;
  color: var(--accent-color);
  font-size: 48px;
  aspect-ratio: 1;
  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) - 5% / 2);
  top: 64px;
}

/* title descr */
ul li .title {
  background: var(--bgColor);
  position: relative;
  font-size: 16px;
  overflow: hidden;
  padding-block-start: 1.5rem;
  font-weight: 500;
}
ul li .descr {
  background: var(--bgColor);
  position: relative;
  font-size: 12px;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
}

/* shadows */
ul li .title::before {
  bottom: calc(100% + 0.125rem);
}

@media (min-width: 40rem) {
  ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  ul::before {
    grid-column: 2;
  }
  ul li:nth-child(odd) {
    grid-column: 1;
  }
  ul li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  ul li:nth-child(2) {
    grid-row: 2/4;
  }

  ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  ul li:nth-child(odd) .date.checked::before {
    left: calc((100% + var(--col-gap) + var(--line-w) + 10%) / 2) !important;
  }

  ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}
</style>